<script>
import { Service } from "../store/service";
import Header from "../views/Header";

const Api = new Service();
export default {
  components: {
    Header
  },
  data() {
    return {
      submittingForm: false,
      selectedBedroom: "1",
      remarks: "",
      selectedWant: "rent",
      whatYouWantList: ["rent", "buy", "mortgage"],
      modalStatus: null,
      propertyTypes: [],
      propertyType: "",
      propertySubTypes: [],
      propertySubType: "",
      locations: [],
      location: "",
      minAmount: 200000,
      maxAmount: "",
      name: "",
      phone: "",
      email: "",
      errorMessages: {
        location: "",
        propertyType: "",
        propertySubType: "",
        price: "",
        name: "",
        phone: "",
        email: "",
        remarks: ""
      }
    };
  },
  mounted() {
    this.fetchProperties();
    this.checkLogger();
    //get property types
    Api.getRequest("fetchpropertytypes")
      .then(result => {
        if (result.data.success) {
          this.propertyTypes = result.data.propertytypes;
        } else {
          throw new Error("unable to get property types");
        }
      })
      .catch(err => {
        console.error(err);
      });

    Api.getRequest("fetchcitiesinnigeria")
      .then(result => {
        if (result.data.success) {
          this.locations = result.data.cities.sort(this.sortLocationAsc);
        } else {
          throw new Error("unable to get locations");
        }
      })
      .catch(err => {
        console.error(err);
      });
  },

  methods: {
    sortLocationAsc(prev, curr) {
      const prevLocationName = prev.name.toLowerCase();
      const currLocationName = curr.name.toLowerCase();

      return prevLocationName < currLocationName ? -1 : 1;
    },
    handleMinAmountInput(e) {
      //strip  commas
      const strippedPrice = e.target.value.replace(/[^0-9]+/g, "");
      this.minAmount = strippedPrice;
      //format
      const formattedPrice = new Intl.NumberFormat().format(strippedPrice);

      e.target.value = formattedPrice;
    },
    handleMaxAmountInput(e) {
      // capture cursor start and end position before stripping values
      const formerSelectionStart = e.target.selectionStart;
      const formerSelectionEnd = e.target.selectionEnd;

      //strip  commas
      const strippedPrice = e.target.value.replace(/[^0-9]+/g, "");
      this.maxAmount = strippedPrice;

      //format
      const formattedPrice = new Intl.NumberFormat().format(strippedPrice);

      // compare difference between old value and cureently formatted value
      const valueDiff = formattedPrice.length - e.target.value.length;

      // set new input value
      e.target.value = formattedPrice;

      //set cursor position, taking into account the difference in value and updating accordingly
      e.target.setSelectionRange(
        formerSelectionStart + valueDiff,
        formerSelectionEnd + valueDiff
      );
    },

    resetForm() {
      (this.location = ""),
        (this.propertyType = ""),
        (this.propertySubType = ""),
        (this.maxAmount = ""),
        (this.minAmount = ""),
        (this.name = ""),
        (this.phone = ""),
        (this.email = ""),
        (this.remarks = "");
      this.selectedWant = "rent";
    },
    submitDetails() {
      const emailTest = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (this.name == "") {
        this.errorMessages.name = "Please input name";
        return;
      } else {
        this.errorMessages.name = "";
      }
      if (!Number(this.phone) || this.phone.length < 11) {
        this.errorMessages.phone =
          "Phone number must be a number and up to 11 digits";
        return;
      } else {
        this.errorMessages.phone = "";
      }

      if (!emailTest.test(this.email)) {
        this.errorMessages.email =
          "Please for email correctly [johndoe@example.com]";
        return;
      } else {
        this.errorMessages.email = "";
      }

      //get city id
      const cityid = this.locations.filter(
        location => (location.name = this.location)
      )[0].id;
      //get property id
      const propertytypeid = this.propertyTypes.filter(
        property => (property.name = this.propertyType)
      )[0].id;
      //get property sub type id
      const subtypeid = this.propertySubTypes.filter(
        property => (property.subtype = this.propertySubType)
      )[0].id;

      let details = {
        email: this.email,
        fullname: this.name,
        cityid,
        propertytypeid,
        subtypeid,
        numberofbedrooms: this.selectedBedroom,
        minprice: this.minAmount,
        maxprice: this.maxAmount,
        phone: this.phone,
        remarks: this.remarks,
        whatdoyouwant: this.selectedWant
      };

      details = JSON.stringify(details);
      this.submittingForm = true;

      Api.postRequest("postpropertyrequest", details)
        .then(data => {
          const response = data.data;
          if (response.success) {
            this.resetForm();

            this.modalStatus = null;
            this.submittingForm = false;

            this.$bvToast.toast("We will call you in 24 hours", {
              variant: "success",
              title: "Successful",
              autoHideDelay: 3000,
              appendToast: false,
              solid: true
            });
            setTimeout(() => {
              this.$router.push("/");
            }, 1500);
          } else {
            this.errorMessages.form = "Unable to submit form";
            this.submittingForm = false;
          }
        })
        .catch(err => {
          console.error(err);
        });
    },

    verifyPropertyDetails() {
      if (this.location == "") {
        this.errorMessages.location = "Please select location";
        return;
      } else {
        this.errorMessages.location = "";
      }

      if (this.propertyType == "") {
        this.errorMessages.propertyType = "Please select property type";
        return;
      } else {
        this.errorMessages.propertyType = "";
      }
      if (this.remarks == "") {
        this.errorMessages.remarks = "Please give your remarks";
        return;
      } else {
        this.errorMessages.remarks = "";
      }
      if (this.propertySubType == "") {
        this.errorMessages.propertySubType = "Please select property sub tyoe";
        return;
      } else {
        this.errorMessages.propertySubType = "";
      }

      if (this.minAmount == "" || this.maxAmount == "") {
        this.errorMessages.price = "Minimum price and Maximum price required";

        return;
      } else if (
        Number(this.minAmount) < 200000 ||
        Number(this.maxAmount) > 10000000
      ) {
        this.errorMessages.price =
          "Prices should not be less than 200,000 and not more than 10,000,000";
        return;
      } else {
        this.errorMessages.price = "";
      }

      this.modalStatus = "pending";
    },
    checkLogger() {
      Api.postRequest(`logcounter`, {})
        .then(res => {
          // console.log("Who reads", res);
        })
        .catch(err => {
          console.log(err);
          this.loader = false;
        });
    },
    moveTo: function(id) {
      this.$router.push({
        path: `/property/${id}`
      });
    },
    addProperty() {
      // localStorage.setItem("shelta-loger", "Tenant");
      this.$router.push({
        path: `/rentpayer`
      });
    },
    fetchProperties: async function() {
      this.loader2 = true;
      try {
        await Api.getRequest(`fetchpropertiesrandomly`).then(res => {
          this.properties = res.data.properties;
          this.loader2 = false;
        });
      } finally {
        console.log("Closed connection");
      }
    },

    // For rent payer

    getPropertyTypes: () => {
      Api.getRequest("fetchpropertytypes")
        .then(result => {
          if (result.data.success) {
            this.propertyTypes = result.data.propertytypes;
          } else {
            throw new Error("unable to get property types");
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  },
  watch: {
    propertyType(newVal) {
      if (newVal) {
        const typeId = this.propertyTypes.filter(type => type.type == newVal)[0]
          .id;

        Api.getRequest("fetchpropertysubtypesbytypeid/" + typeId)
          .then(result => {
            if (result.data.success) {
              this.propertySubTypes = result.data.propertysubtypes;
            } else {
              throw new Error("unable to get property subtypes");
            }
          })
          .catch(err => {
            console.error(err);
          });
      }
    }
  }
};
</script>
<template>
  <div class="find_property">
    <div class="header">
      <Header />
    </div>

    <div class="container main-container">
      <!-- <div class="page-image">
        <img src="../assets/images/join_waitlist.svg" alt="join waitlist" />
      </div> -->
      <div class="d-block  form-container ">
        <div class="text-center mb-5 user-form-head">
          <div class="mb-2">
            <img src="@/assets/logo.svg" alt="shelta logo" />
          </div>
          <h2>
            Tell Us What You Need
          </h2>

          <p>
            ... got you covered
          </p>
        </div>
        <!-- MAIN FORM -->

        <div v-if="modalStatus == null">
          <div>
            <form class="text-left">
              <div class="form-group">
                <label class="select-label " for="subtype"
                  >What do you want?
                </label>

                <!-- bedroom select container -->
                <div class="what-you-want">
                  <!-- bedroom-radio -->

                  <div
                    class=" custom-radio"
                    :class="{ active: selectedWant == want }"
                    v-for="want in whatYouWantList"
                    :key="want"
                    @click="selectedWant = want"
                  >
                    <i
                      v-if="selectedWant == want"
                      class="fa fa-check-circle-o"
                      aria-hidden="true"
                    ></i>

                    {{ want }}
                  </div>
                </div>
              </div>

              <div class="form-group email-group">
                <label class="select-label " for="location"
                  >Property Location
                </label>
                <select
                  v-model="location"
                  id="location"
                  name="location"
                  class="form-control form-select"
                >
                  <option value="" selected disabled>--select--</option>
                  <option v-for="location in locations" :key="location.id">{{
                    location.name
                  }}</option>
                </select>
                <p v-if="errorMessages.location" class="input-error">
                  {{ errorMessages.location }}
                </p>
              </div>

              <div class="property-types-container">
                <div class="form-group">
                  <label class="select-label " for="propertyType"
                    >Property Type
                  </label>
                  <select
                    v-model="propertyType"
                    id="propertyType"
                    name="propertyType"
                    class="form-control form-select"
                  >
                    <option value="" selected disabled>--select--</option>
                    <option
                      v-for="property in propertyTypes"
                      :key="property.id"
                      >{{ property.type }}</option
                    >
                  </select>
                  <p v-if="errorMessages.propertyType" class="input-error">
                    {{ errorMessages.propertyType }}
                  </p>
                </div>
                <div class="form-group">
                  <label class="select-label " for="subtype"
                    >Property Sub Type
                  </label>
                  <select
                    v-model="propertySubType"
                    id="subtype"
                    name="subtype"
                    class="form-control form-select"
                  >
                    <option value="" selected disabled>--select--</option>
                    <option
                      v-for="property in propertySubTypes"
                      :key="property.id"
                      >{{ property.subtype }}</option
                    >
                  </select>
                  <p v-if="errorMessages.propertySubType" class="input-error">
                    {{ errorMessages.propertySubType }}
                  </p>
                </div>
              </div>

              <div class="form-group" v-if="propertyType == 'Residential'">
                <label class="select-label " for="subtype">Bedrooms </label>

                <!-- bedroom select container -->
                <div class="bedroom-select-container">
                  <!-- bedroom-radio -->

                  <div
                    class="bedroom-radio"
                    :class="{ 'bedroom-active': selectedBedroom == n }"
                    v-for="n in 5"
                    :key="n"
                    @click="selectedBedroom = n"
                  >
                    {{ n + `${n >= 5 ? "+" : ""}` }}
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label> Price range</label>

                <div class="price-range-input-container">
                  <input
                    :value="new Intl.NumberFormat().format(minAmount)"
                    @input="handleMinAmountInput"
                    placeholder="min"
                    type="text"
                    name="min-price"
                    id=""
                  />
                  <div class="dash">-</div>
                  <input
                    @input="handleMaxAmountInput"
                    placeholder="max"
                    type="text"
                    name="max-price"
                    id=""
                    min="300000"
                  />
                </div>
                <p v-if="errorMessages.price" class="input-error">
                  {{ errorMessages.price }}
                </p>
              </div>
              <div class="form-group">
                <label>Remark/Comments</label>
                <textarea v-model.trim="remarks"> </textarea>

                <p v-if="errorMessages.remarks" class="input-error">
                  {{ errorMessages.remarks }}
                </p>
              </div>
            </form>
          </div>

          <div class="modal-next btn" @click="verifyPropertyDetails">
            Next
          </div>
        </div>

        <!-- FEEDBACK DETAILS FORM -->

        <div v-if="modalStatus == 'pending'">
          <p v-if="errorMessages.form" class="input-error">
            {{ errorMessages.form }}
          </p>

          <div>
            <form @submit.prevent="submitDetails" class="text-left">
              <div class="form-group ">
                <label class="select-label" for="name">Name</label>
                <input
                  id="name"
                  class="form-control form-select"
                  type="text"
                  placeholder="Enter your name"
                  v-model.trim="name"
                  required
                />
                <p v-if="errorMessages.name" class="input-error">
                  {{ errorMessages.name }}
                </p>
              </div>
              <div class="form-group">
                <label class="select-label" for="title">Phone Number</label>
                <input
                  id="title"
                  maxlength="11"
                  class="form-control form-select"
                  type="number"
                  placeholder="080xxxxxxxx"
                  v-model="phone"
                  required
                />
                <p v-if="errorMessages.phone" class="input-error">
                  {{ errorMessages.phone }}
                </p>
              </div>
              <div class="form-group">
                <label class="select-label" for="title">E-mail</label>
                <input
                  id="title"
                  maxlength="40"
                  class="form-control form-select"
                  type="email"
                  placeholder="johndoe@email.com"
                  required
                  v-model="email"
                />
                <p v-if="errorMessages.email" class="input-error">
                  {{ errorMessages.email }}
                </p>
              </div>
              <button class="modal-next btn" :disabled="submittingForm">
                {{ submittingForm ? "Submitting" : "submit" }}
              </button>
            </form>
          </div>
        </div>

        <!-- success modal -->
        <div v-if="modalStatus == 'success'" class="success-container">
          <div class="success-logo">
            <img src="../assets/images/blue_check.svg" alt="" />
          </div>
          <p>.... We Got You Covered !</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
$primary: #0033ea;
$secondary: #000000;
$tertiary: #a2b0e3;
$whiteText: #ffffff;
$yellow: #ffb100;
$listing-bg: #64abb2;
$fontFamily: linernear;
p,
label {
  margin-bottom: 0 !important;
}
.main-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-top: 2rem;
}

.page-image {
  display: none;
  @media screen and (min-width: 1024px) {
    display: block;
  }
}

.find_property {
  background-color: #f2f5fe;
  min-height: 100vh;
}

.modal-header-container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.user-form-head {
  h2 {
    font-weight: 700;
    font-size: 2rem;
  }

  p {
    font-size: 14px;
  }
}

.header {
  position: sticky;
  top: 0;

  .nav_holder {
    position: relative;
    margin-bottom: 0;
  }
}

.input-error {
  font-size: 12px;
  color: red;
}

.modal-next {
  background-color: $primary;
  width: 100%;
  color: #ffffff;
  margin: 1rem 0;
  &:hover {
    color: #ffffff;
    opacity: 0.9;
  }
  &[disabled] {
    opacity: 0.5;
  }
}

form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  label {
    text-align: left;
  }
}

.form-container {
  padding: 2rem;
  background-color: #ffffff;
  padding-bottom: 10rem;
  margin-bottom: 1rem;
}

.property-types-container {
  display: grid;
  gap: 1rem;

  /* @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  } */
}

.form-header {
  font-weight: 700;
  font-size: 2rem;
  text-transform: uppercase;
}

.what-you-want {
  display: flex;
}

.bedroom-select-container {
  flex-wrap: wrap;
  display: flex;
  gap: 8px;
}

.custom-radio {
  border: 1px solid $primary;
  color: $primary;
  font-size: 1rem;
  padding: 5px 15px;
  display: inline-block;
  font-weight: 300;
  text-transform: capitalize;
  cursor: pointer;
  &.active {
    background-color: #0033ea;
    color: #ffffff;

    .icon {
      display: block;
    }
  }
}

.bedroom-radio {
  border: 1px solid $primary;
  color: $primary;
  border-radius: 5px;
  font-size: 1rem;
  padding: 5px 15px;
  display: inline-block;
  font-weight: 300;
  cursor: pointer;

  &.bedroom-active {
    background-color: #0033ea;
    color: #ffffff;
  }
}

.price-range-input-container {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  gap: 1rem;

  .dash {
    font-weight: 700;
    font-size: 2rem;
  }

  input {
    border: 1px solid $primary;
    padding: 3px 10px;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  display: block;
  width: 100%;
  min-height: 10rem;
  padding: 1rem;
}
</style>
